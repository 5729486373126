import {
  BooleanField,
  BulkUpdateWithConfirmButton,
  DatagridConfigurable,
  DateField,
  DateInput,
  downloadCSV,
  Edit,
  ExportButton,
  FilterButton,
  Form,
  FunctionField,
  Labeled,
  List,
  SaveButton,
  SelectColumnsButton,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useRecordContext,
  useTranslate,
  WrapperField,
} from "react-admin";
import Currency from "../components/currency";
import { Card, Typography, CardContent, Grid, Link, ThemeProvider, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import jsonExport from "jsonexport/dist";
import OrdersList from "../components/orderList";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import PanToolIcon from "@mui/icons-material/PanTool";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import { useEffect, useState } from "react";
import { useOrderInfo } from "../hooks/useOrder";
import CartList from "../components/cartList";
import { __DEV__, braintree_link, stripe_link } from "../api";
import React from "react";
import exporterShipping from "../exporter/orderCsv";
import { exporterDiscountXlsx, exporterXlsx } from "../exporter/orderXlsx";
import { DropDownMenu } from "../components/dropDownMenu";
import DownloadIcon from "@mui/icons-material/Download";
import getTranslationJson from "../components/getTranslationJson";

const MyActions = () => (
  <TopToolbar>
    <FilterButton />
    <SelectColumnsButton />
    <DropDownMenu icon={<DownloadIcon />} label={"Export Menu"}>
      <ExportButton label="CSV Orders" maxResults={1000000} />
      <ExportButton label="CSV Shipping" exporter={exporterShipping} maxResults={1000000} />
      <ExportButton label="CSV Discount" exporter={exporterDiscount} maxResults={1000000} />
      <ExportButton label="XLSX Orders" exporter={exporterXlsx} maxResults={1000000} />
      <ExportButton label="XLSX Discount" exporter={exporterDiscountXlsx} maxResults={1000000} />
    </DropDownMenu>
  </TopToolbar>
);

const date = new Date();
const firstDay = new Date(date.getFullYear(), date.getMonth(), 1).toISOString();

export const orderFilters = [
  <TextInput label="ID" source="pk" alwaysOn />,
  <TextInput label="Email" source="email__icontains" alwaysOn defaultValue="" />,
  <DateInput label="Date from" source="created_at__gte" alwaysOn defaultValue="" />,
  <DateInput label="Date to" source="created_at__lte" alwaysOn defaultValue="" />,
  <TextInput label="Shipping Name" source="shipping_name__icontains" alwaysOn defaultValue="" />,
  <TextInput label="Payment" source="payment_type__icontains" defaultValue="" />,
  <TextInput label="Coupon Code" source="couponcode__icontains" defaultValue="" />,
  // <TextInput source="currency__icontains" defaultValue="" />,
  <SelectInput
    label="Type"
    source="order_type"
    choices={[
      { id: "new", name: "new" },
      { id: "renew_first", name: "renew_first" },
      { id: "renew", name: "renew" },
    ]}
  />,
  <SelectInput
    label="Currency"
    source="currency__icontains"
    choices={[
      { id: "EUR", name: "EUR" },
      { id: "GBP", name: "GBP" },
      { id: "USD", name: "USD" },
    ]}
  />,
  <SelectInput
    label="Shipping Status"
    source="shipping_state"
    alwaysOn
    choices={[
      { id: "ordered", name: "Ordered" },
      { id: "process_manually", name: "Process Manually" },
      { id: "sent_to_warewhouse", name: "Sent To Warewhouse" },
      { id: "tracking_email_sent", name: "Tracking Email Sent" },
      { id: "complete", name: "Complete" },
      { id: "cancelled", name: "Cancelled" },
    ]}
  />,
];

const exporter = (orders: [GQL.Orders]) => {
  const ordersForExport = orders.map((order: GQL.Orders) => {
    const {
      currencySymbol,
      shippingName,
      // amountTax,
      // amountDiscount,
      amountShipping,
      amountSubtotal,
      amountTotalAfter,
      billingName,
      billingAddressCity,
      billingAddressLine1,
      billingAddressLine2,
      billingAddressPostalCode,
      billingAddressCountry,
      billingAddressState,
      country,
      couponCodes,
      discounts,
      hasSubscription,
      hasTrial,
      language,
      modifiedAt,
      orderType,
      paymentType,
      stripeSubscriptionId,
      stripeTaxRateId,
      tags,
      taxRate,
      trialDays,
      userId,
      // orderItems,
      orderId,
      invoicePaymentReferenceId,
      braintreePaypalTransactionId,
      stripeSetupIntent,
      stripePaymentIntent,
      // firstName lastName companyName stripeInvoiceIds stripeSubscriptionIds amountDiscountRate amountTotalValue

      ...orderForExport
    } = order; // omit backlinks and author

    if (orderForExport !== null && orderForExport.orderItems !== null && orderForExport.orderItems !== undefined) {
      const orderItemsForExport = orderForExport.orderItems.map((orderItem: any) => {
        const {
          bundleId,
          bundleName,
          currencySymbol,
          image,
          infoDelivery,
          infoExplainer,
          infoPayment,
          isSubscription,
          priceAmount,
          priceAmountAfterTrial,
          priceDefaultAmount,
          priceId,
          priceOriginalAfterTrial,
          recurringInterval,
          recurringIntervalCount,
          recurringTrialPeriodDays,
          ...orderItemForExport
        } = orderItem; // omit backlinks and author

        orderItemForExport.interval = orderItem.recurringIntervalCount + " " + orderItem.recurringInterval;
        if (orderItemForExport.interval === "12 month") {
          orderItemForExport.interval = "1 year";
        }
        return orderItemForExport;
      });

      orderForExport.orderItems = orderItemsForExport;
    }

    // // orderForExport.tags = orderItems.tags;
    // // orderForExport.productName = orderItems.productName;
    // // orderForExport.productId = orderItems.productId;
    // }
    //   return orderItem;
    // })
    // orderForExport.orderItems = orderItemsForExport;
    // orderForExport.author_name = post.author.name; // add a field
    return orderForExport;
  });

  jsonExport(
    ordersForExport,
    {
      headers: [
        "id",
        "firstName",
        "lastName",
        "companyName",
        // "shippingName",
        "shippingAddressLine1",
        "shippingAddressLine2",
        "shippingAddressPostalCode",
        "shippingAddressCity",
        "shippingAddressState",
        "shippingAddressCountry",
        "phone",
        "amountTotal",
        "email",
        "orderItems.quantity", //
        "orderItems.productSku",
        "orderItems.tags", //
        "orderItems.productName", //
        "orderItems.productNameMarketing", //
        "orderItems.productNameOrder", //
        "orderItems.productBarcode",
        "orderItems.productId", //
        "couponcode",
        "createdAt",
        "stripeCustomerId",
        "stripeInvoiceId",
        "braintreeCustomerId",
        "amountTax",
        "amountDiscount",
        "amountDiscountRate",
        "amountTotalValue",
        // "amountTotal",
        "currency",
        "orderItems.interval",
      ], // order fields in the export
      rename: [
        "Order Id",
        "First Name",
        "Last Name",
        "Company Name",
        // "Shipping Name",
        "Address Line1",
        "Address Line2",
        "PostalCode",
        "City",
        "State",
        "Country",
        "Phone",
        "Total",
        // "Currency",
        "Email",
        "Quantity", //
        "Sku",
        "Tags", //
        "Product Name", //
        "Marketing Name", //
        "Order Name", //
        "Barcode",
        "Product stripe Id", //
        "Couponcode",
        "Created at",
        "Stripe Customer Id",
        "Stripe Invoice Id",
        "Braintree Customer Id",
        "Tax",
        "Discount",
        "Discount Rate",
        "Total Value",
        // "Total",
        "Currency",
        "Recurring Interval",
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Orders"); // download as 'posts.csv` file
    }
  );
};

const exporterDiscount = (orders: [GQL.Orders]) => {
  const ordersForExport = orders.map((order: GQL.Orders) => {
    const {
      currencySymbol,
      shippingName,
      // amountTax,
      // amountDiscount,
      // amountShipping,
      // amountSubtotal,
      amountTotalAfter,
      billingName,
      billingAddressCity,
      billingAddressLine1,
      billingAddressLine2,
      billingAddressPostalCode,
      billingAddressCountry,
      billingAddressState,
      country,
      couponCodes,
      discounts,
      hasSubscription,
      hasTrial,
      language,
      modifiedAt,
      orderType,
      paymentType,
      stripeSubscriptionId,
      stripeTaxRateId,
      tags,
      taxRate,
      trialDays,
      userId,
      orderItems,
      orderId,
      invoicePaymentReferenceId,
      braintreePaypalTransactionId,
      stripeSetupIntent,
      stripePaymentIntent,
      // firstName lastName companyName stripeInvoiceIds stripeSubscriptionIds amountDiscountRate
      amountTotalValue,
      braintreeCustomerId,
      shippingAddressLine1,
      shippingAddressLine2,
      shippingAddressPostalCode,
      shippingAddressCity,
      shippingAddressState,
      shippingAddressCountry,
      stripeCustomerId,
      stripeInvoiceId,
      phone,
      stripeInvoiceIds,
      stripeSubscriptionIds,

      ...orderForExport
    } = order; // omit backlinks and author

    // if (orderForExport !== null && orderForExport.orderItems !== null && orderForExport.orderItems !== undefined) {
    //   const orderItemsForExport = orderForExport.orderItems.map((orderItem: any) => {
    //     const {
    //       bundleId,
    //       bundleName,
    //       currencySymbol,
    //       image,
    //       infoDelivery,
    //       infoExplainer,
    //       infoPayment,
    //       isSubscription,
    //       priceAmount,
    //       priceAmountAfterTrial,
    //       priceDefaultAmount,
    //       priceId,
    //       priceOriginalAfterTrial,
    //       recurringInterval,
    //       recurringIntervalCount,
    //       recurringTrialPeriodDays,
    //       productId,
    //       tags,
    //       productBarcode,
    //       productNameMarketing,
    //       productNameOrder,
    //       ...orderItemForExport
    //     } = orderItem; // omit backlinks and author
    //     orderItemForExport.priceAmount = orderItemForExport.priceAmount ? orderItemForExport.priceAmount / 100 : 0;
    //     return orderItemForExport;
    //   });

    //   orderForExport.orderItems = orderItemsForExport;
    // }

    orderForExport.amountDiscount = orderForExport.amountDiscount ? orderForExport.amountDiscount / 100 : 0;
    orderForExport.amountSubtotal = orderForExport.amountSubtotal ? orderForExport.amountSubtotal / 100 : 0;
    orderForExport.amountShipping = orderForExport.amountShipping ? orderForExport.amountShipping / 100 : 0;
    orderForExport.amountTax = orderForExport.amountTax ? orderForExport.amountTax / 100 : 0;
    orderForExport.amountTotal = orderForExport.amountTotal ? orderForExport.amountTotal / 100 : 0;
    // orderForExport.amountTotalValue = orderForExport.amountTotalValue ? orderForExport.amountTotalValue / 100 : 0;
    // // orderForExport.quantity = orderItems.quantity;
    // // orderForExport.tags = orderItems.tags;
    // // orderForExport.productName = orderItems.productName;
    // // orderForExport.productId = orderItems.productId;
    // }
    //   return orderItem;
    // })
    // orderForExport.orderItems = orderItemsForExport;
    // orderForExport.author_name = post.author.name; // add a field
    return orderForExport;
  });

  jsonExport(
    ordersForExport,
    {
      headers: [
        "id",
        "createdAt",
        "firstName",
        "lastName",
        "companyName",
        "email",
        "couponcode",
        "amountDiscount",
        // "amountTotalValue",
        "amountDiscountRate",
        "amountSubtotal",
        "amountShipping",
        "amountTax",
        "amountTotal",
        "currency",
        // "shippingName",
        // "shippingAddressLine1",
        // "shippingAddressLine2",
        // "shippingAddressPostalCode",
        // "shippingAddressCity",
        // "shippingAddressState",
        // "shippingAddressCountry",
        // "phone",
        // "orderItems.productSku",
        // "orderItems.productName", //
        // // "amountTotal",
        // "orderItems.priceAmount",
        // "orderItems.quantity", //
        // "orderItems.tags", //
        // "orderItems.productNameMarketing", //
        // "orderItems.productNameOrder", //
        // "orderItems.productBarcode",
        // "orderItems.productId", //
        // "stripeCustomerId",
        // "stripeInvoiceId",
        // "braintreeCustomerId",
      ], // order fields in the export
      rename: [
        "Order Id",
        "Created at",
        "First Name",
        "Last Name",
        "Company Name",
        "Email",
        "Couponcode",
        "Discount Amount",
        // "Total Value",
        "Discount Rate",
        "Original Value",
        "Shipping cost",
        "Tax",
        "Invoiced Total",
        "Currency",
        // "Shipping Name",
        // "Address Line1",
        // "Address Line2",
        // "PostalCode",
        // "Shipping City",
        // "State",
        // "Country",
        // "Phone",
        // "Sku",
        // "Product Name", //
        // "Line Item Price",
        // // "Currency",
        // "Line Item Quantity", //
        // "Tags", //
        // "Marketing Name", //
        // "Order Name", //
        // "Barcode",
        // "Product stripe Id", //
        // "Stripe Customer Id",
        // "Stripe Invoice Id",
        // "Braintree Customer Id",
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Orders"); // download as 'posts.csv` file
    }
  );
};

const MyToolbar = (props: any) => {
  // const redirect = useRedirect();
  // const notify = useNotify();
  return (
    <Toolbar {...props}>
      <SaveButton />
    </Toolbar>
  );
};
export const MyBulkActionButtons = () => (
  <>
    {/* <Confirm label="Reset Views" /> */}
    <BulkUpdateWithConfirmButton
      icon={<LocalShippingIcon />}
      data={{ shippingState: "sent_to_warewhouse" }}
      label="Send to Warehouse"
    />
    {/* <BulkUpdateWithConfirmButton data={{ shippingState: "cancelled" }} label="Set State Canceled" /> */}
    <BulkUpdateWithConfirmButton
      icon={<CancelPresentationIcon />}
      data={{ shippingState: "cancelled" }}
      label="Set State Canceled"
    />
    <BulkUpdateWithConfirmButton
      icon={<PanToolIcon />}
      data={{ shippingState: "process_manually" }}
      label="Set State Manual"
    />
    <BulkUpdateWithConfirmButton
      icon={<BeenhereIcon />}
      data={{ shippingState: "complete" }}
      label="Set State Complete"
    />
    {/* default bulk delete action */}
    {/* <BulkDeleteButton /> */}
  </>
);
export const OrderList = () => (
  <List
    sort={{ field: "createdAt", order: "DESC" }}
    filters={orderFilters}
    filterDefaultValues={{ created_at__gte: firstDay }}
    exporter={exporter}
    actions={<MyActions />}
  >
    <DatagridConfigurable omit={["address", "shippingName", "couponcode"]} bulkActionButtons={<MyBulkActionButtons />}>
      {/* rowClick="edit" */}
      <DateField label="Date" source="createdAt" showTime />
      {/* <DateField label="Date" source="modifiedAt" showTime /> */}
      <TextField label="Order Id" source="id" />
      <TextField label="Type" source="orderType" />
      <TextField label="Payment" source="paymentType" />
      <TextField label="Coupon Code" source="couponcode" />
      {/* <UrlField source="invoiceLink" /> */}
      <FunctionField
        label="Invoice"
        render={(record: GQL.Orders) =>
          (record.stripeInvoiceIds !== null &&
            record.stripeInvoiceIds !== undefined &&
            record.stripeInvoiceIds.length > 1 &&
            record.stripeInvoiceIds.map((stripeInvoiceId, i) => (
              <a
                key={stripeInvoiceId}
                target="_blank"
                rel="noreferrer"
                href={stripe_link + "invoices/" + stripeInvoiceId}
              >
                Invoice_{i + 1}
                <br />
              </a>
            ))) ||
          (record.stripeInvoiceId !== null && record.stripeInvoiceId && (
            <a target="_blank" rel="noreferrer" href={stripe_link + "invoices/" + record.stripeInvoiceId}>
              Invoice
            </a>
          ))
        }
      />
      <FunctionField
        label="Subscription"
        render={(record: GQL.Orders) =>
          (record.stripeSubscriptionIds !== null &&
            record.stripeSubscriptionIds !== undefined &&
            record.stripeSubscriptionIds.length > 1 &&
            record.stripeSubscriptionIds.map((stripeSubscriptionId, i) => (
              <a
                key={stripeSubscriptionId}
                target="_blank"
                rel="noreferrer"
                href={stripe_link + "subscriptions/" + stripeSubscriptionId}
              >
                Subscription_{i + 1}
                <br />
              </a>
            ))) ||
          (record.stripeSubscriptionId !== null && record.stripeSubscriptionId && (
            <a target="_blank" rel="noreferrer" href={stripe_link + "subscriptions/" + record.stripeSubscriptionId}>
              Subscription
            </a>
          ))
        }
      />
      <FunctionField
        label="Payment Id"
        render={(record: GQL.Orders) =>
          record.stripeSetupIntent
            ? record.stripeSetupIntent && (
                <a target="_blank" rel="noreferrer" href={stripe_link + "setup_intents/" + record.stripeSetupIntent}>
                  SetupIntent
                </a>
              )
            : record.stripePaymentIntent
            ? record.stripePaymentIntent && (
                <a target="_blank" rel="noreferrer" href={stripe_link + "payments/" + record.stripePaymentIntent}>
                  PaymentIntent
                </a>
              )
            : record.braintreePaypalTransactionId && (
                <a target="_blank" rel="noreferrer" href={braintree_link + record.braintreePaypalTransactionId}>
                  Braintree Transaction
                </a>
              )
        }
      />
      <TextField label="Name" source="shippingName" />
      <TextField label="Email" source="email" />
      <FunctionField
        textAlign="left"
        label="Address"
        source="address"
        render={(record: GQL.Orders) => {
          return [
            record.shippingAddressPostalCode,
            record.shippingAddressCity,
            record.shippingAddressLine1,
            record.shippingAddressLine2,
            record.shippingAddressCountry,
          ].join(" ");
        }}
      />
      <FunctionField
        textAlign="left"
        label="Nb Items"
        render={(record: GQL.Orders) => {
          return record.orderItems?.length;
        }}
      />
      <FunctionField
        textAlign="right"
        label="Total"
        render={(record: { amountTotal: number; currency: string }) => {
          return <Currency price={record.amountTotal} currency={record.currency} />;
        }}
      />
      <FunctionField
        textAlign="right"
        label="Shipping Items Count"
        render={(record: GQL.Orders) => {
          let countOutput = "";
          if (record.shippingItemsBrushCount !== undefined) {
            countOutput += "B:" + record.shippingItemsBrushCount;
          }
          if (record.shippingItemsBrushHeadCount !== undefined) {
            countOutput += " BH:" + record.shippingItemsBrushHeadCount;
          }
          if (record.shippingItemsGamesSubCount !== undefined) {
            countOutput += " G:" + record.shippingItemsGamesSubCount;
          }
          if (record.shippingItemsToothPasteCount !== undefined) {
            countOutput += " T:" + record.shippingItemsToothPasteCount;
          }
          return countOutput;
        }}
      />
      <TextField label="Shipping State" source="shippingState" />
      <TextField label="Shipping Courier" source="shippingCourierService" />
      {/* <TextField label="Product Id" source="product.productId" />
        <ImageField
          label="Product Image"
          sx={{ "& img": { maxWidth: 100, maxHeight: 50, objectFit: "contain" } }}
          source="product.image"
        />
        <TextField label="Product Name" source="product.name" />
        */}
      {/* <EditButton /> */}
      <WrapperField label="Actions">
        <ShowButton />
      </WrapperField>
    </DatagridConfigurable>
  </List>
);

export const OrderEdit = () => (
  <Edit title={<OrderTitle />} component="div">
    <OrderForm />
  </Edit>
);

const OrderTitle = () => {
  const record = useRecordContext<GQL.Orders>();
  return record ? <span>Order</span> : null;
};

const CustomerDetails = () => {
  const record = useRecordContext<GQL.Orders>();
  return (
    <div>
      <Typography style={{ textDecoration: "none" }}>{record?.shippingName}</Typography>
      <Typography component={Link} color="primary" href={`mailto:${record?.email}`} style={{ textDecoration: "none" }}>
        {record?.email}
      </Typography>
      <Typography style={{ textDecoration: "none" }}>{record?.phone}</Typography>
      {record?.braintreePaypalPayerEmail && (
        <Typography style={{ textDecoration: "none" }}>Paypal Email: {record?.braintreePaypalPayerEmail}</Typography>
      )}
    </div>
  );
};

const CustomerAddress = () => {
  const record = useRecordContext<GQL.Orders>();
  if (__DEV__) {
    console.log("CustomerAddresRecord", record);
  }
  return (
    <div>
      <Typography>{record?.shippingName}</Typography>
      <Typography>
        {record?.shippingAddressLine1} {record?.shippingAddressLine2}
      </Typography>
      <Typography>
        {record?.shippingAddressPostalCode} {record?.shippingAddressCity} {record?.shippingAddressState}
      </Typography>
    </div>
  );
};
const StripeLink = () => {
  const record = useRecordContext<GQL.Orders>();
  if (__DEV__) {
    console.log("StripeLinkRecord", record);
  }
  return (
    <div>
      <Typography>{record.paymentType}</Typography>
      <Typography>
        {(record.stripeInvoiceIds !== null &&
          record.stripeInvoiceIds !== undefined &&
          record.stripeInvoiceIds.length > 1 &&
          record.stripeInvoiceIds.map((stripeInvoiceId, i) => (
            <a
              key={stripeInvoiceId}
              target="_blank"
              rel="noreferrer"
              href={stripe_link + "invoices/" + stripeInvoiceId}
            >
              Invoice_{i + 1}
              <br />
            </a>
          ))) ||
          (record.stripeInvoiceId !== null && record.stripeInvoiceId && (
            <a target="_blank" rel="noreferrer" href={stripe_link + "invoices/" + record.stripeInvoiceId}>
              Invoice
            </a>
          ))}
      </Typography>
      <Typography>
        {(record.stripeSubscriptionIds !== null &&
          record.stripeSubscriptionIds !== undefined &&
          record.stripeSubscriptionIds.length > 1 &&
          record.stripeSubscriptionIds.map((stripeSubscriptionId, i) => (
            <a
              key={stripeSubscriptionId}
              target="_blank"
              rel="noreferrer"
              href={stripe_link + "subscriptions/" + stripeSubscriptionId}
            >
              Subscription_{i + 1}
              <br />
            </a>
          ))) ||
          (record.stripeSubscriptionId !== null && record.stripeSubscriptionId && (
            <a target="_blank" rel="noreferrer" href={stripe_link + "subscriptions/" + record.stripeSubscriptionId}>
              Subscription
            </a>
          ))}
      </Typography>
      <Typography>
        {record.stripeSetupIntent
          ? record.stripeSetupIntent && (
              <a target="_blank" rel="noreferrer" href={stripe_link + "setup_intents/" + record.stripeSetupIntent}>
                SetupIntent
              </a>
            )
          : record.stripePaymentIntent
          ? record.stripePaymentIntent && (
              <a target="_blank" rel="noreferrer" href={stripe_link + "payments/" + record.stripePaymentIntent}>
                PaymentIntent
              </a>
            )
          : record.braintreePaypalTransactionId && (
              <a target="_blank" rel="noreferrer" href={braintree_link + record.braintreePaypalTransactionId}>
                Braintree Transaction
              </a>
            )}
      </Typography>
    </div>
  );
};

const Spacer = () => <Box m={1}>&nbsp;</Box>;

const OrderForm = () => {
  const theme = useTheme();
  const record = useRecordContext<GQL.Orders>();
  const translate = useTranslate();
  const mutationOrderInfo = useOrderInfo();
  const [cart, setCart] = useState<GQL.CheckoutOpen | null>(null);

  useEffect(() => {
    if (record !== null) {
      mutationOrderInfo.mutate(
        { orderId: record.id },
        {
          onSuccess: (data) => {
            if (data.cart !== undefined) {
              setCart(data.cart);
            }
          },
        }
      );
    }
  }, [record]);

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Form>
          <Box>
            <Card>
              <CardContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={8}>
                    <Typography variant="h6" gutterBottom>
                      {translate("resources.commands.section.order")}
                    </Typography>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={6}>
                        <Labeled source="date">
                          <DateField source="createdAt" showTime />
                        </Labeled>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6}>
                        <Labeled source="id">
                          <TextField source="id" />
                        </Labeled>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={4}>
                        <Labeled source="hasSubscription">
                          <BooleanField source="hasSubscription" />
                        </Labeled>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <Labeled source="hasTrial">
                          <BooleanField source="hasTrial" />
                        </Labeled>
                      </Grid>
                      <Grid item xs={12} sm={12} md={4}>
                        <Labeled source="trialDays">
                          <TextField source="trialDays" />
                        </Labeled>
                      </Grid>
                    </Grid>
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12}>
                        <Labeled source="amountSubtotal">
                          <FunctionField
                            textAlign="right"
                            label="Subtotal"
                            render={(record: GQL.Orders) => {
                              return <Currency price={record.amountSubtotal!} currency={record.currency!} />;
                            }}
                          />
                        </Labeled>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Labeled source="amountShipping">
                          <FunctionField
                            textAlign="right"
                            label="Shipping"
                            render={(record: GQL.Orders) => {
                              return <Currency price={record.amountShipping!} currency={record.currency!} />;
                            }}
                          />
                        </Labeled>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Labeled source="amountDiscount">
                          <FunctionField
                            textAlign="right"
                            label="Discount"
                            render={(record: GQL.Orders) => {
                              return <Currency price={record.amountDiscount!} currency={record.currency!} />;
                            }}
                          />
                        </Labeled>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Labeled source="amountTax">
                          <FunctionField
                            textAlign="right"
                            label="Tax"
                            render={(record: GQL.Orders) => {
                              return <Currency price={record.amountTax!} currency={record.currency!} />;
                            }}
                          />
                        </Labeled>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12}>
                        <Labeled source="amountTotal">
                          <FunctionField
                            textAlign="right"
                            label="Total"
                            render={(record: GQL.Orders) => {
                              return <Currency price={record.amountTotal!} currency={record.currency!} />;
                            }}
                          />
                        </Labeled>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={4}>
                    <Typography variant="h6" gutterBottom>
                      {translate("resources.commands.section.customer")}
                    </Typography>
                    {/* <ReferenceField source="customer_id" reference="customers" link={false}> */}
                    <CustomerDetails />
                    {/* </ReferenceField> */}
                    <Spacer />

                    <Typography variant="h6" gutterBottom>
                      {translate("resources.commands.section.shipping_address")}
                    </Typography>
                    {/* <ReferenceField source="customer_id" reference="customers" link={false}> */}
                    <CustomerAddress />
                    <Spacer />
                    <Typography variant="h6" gutterBottom>
                      {translate("resources.commands.section.stripe_links")}
                    </Typography>
                    <StripeLink />
                  </Grid>
                </Grid>
                <Spacer />
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ paddingRight: "30px" }}>
                    <OrdersList order={record} />
                  </Box>
                  <Box>
                    {cart && (
                      <CartList
                        cart={cart}
                        translation={getTranslationJson(cart.language?.toUpperCase() || "")}
                        listType={"ORDER"}
                      />
                    )}
                  </Box>
                </Box>
                <Spacer />

                <div>{/* <Totals /> */}</div>
                {/* <Typography variant="h6" gutterBottom>
              {translate("resources.commands.section.total")}
            </Typography> */}
                <div>{/* <Totals /> */}</div>
              </CardContent>
              {/* <Toolbar /> */}
            </Card>
          </Box>
        </Form>
      </Box>
    </ThemeProvider>
  );
};
