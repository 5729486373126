import { omit, pick } from "lodash";
import axios from "axios";
import { apiBaseUrl, processENV } from "../api";

const getAccessKey = () => {
  let cookie = localStorage.getItem("PB_Auth");
  if (cookie) {
    const { state } = JSON.parse(cookie);
    return state;
  }
  return "";
};

const requestConfig = () => {
  return {
    baseURL: processENV.REACT_APP_KIDS_API_URL + "/gql/v1/graphql",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json",
      Authorization: getAccessKey(),
    },
  };
};

const axios_client = axios.create(requestConfig());

axios.interceptors.request.use(
  function (_config) {
    return requestConfig;
  },
  function (error) {
    return Promise.reject(error);
  }
);

const fields = (resource) => {
  switch (resource) {
    case "products":
    case "productpres":
      return `id description descriptionEn descriptionDe image name productId metadata tags defaultPrice currentPriceEur
      crossedPriceEur currentPriceGbp crossedPriceGbp currentPriceUsd crossedPriceUsd prodOrderTitleEn prodOrderTitleDe
      prodMarketingTitleEn prodMarketingTitleDe prodImageTitleEn prodImageTitleDe prodImageCornerNoteEn prodImageCornerNoteDe
      prodImageTooltipTextEn prodImageTooltipTextDe prodSku prodBarcode`;
    case "v2Products":
    case "v2Productpres":
      return `id productId name prodSku prodBarcode image locales tags
      translations {description prodOrderTitle prodMarketingTitle prodImageTitle prodImageCornerNote prodImageTooltipText images}
      prices {crossedPrice currentPrice recurringInterval recurringIntervalCount recurringTrialPeriodDays productSku}`;
    case "productTags":
      return "id name tagId";
    case "emailProductDetailText":
      return "id emailType tags fieldName language text";
    case "prices":
      return "id priceId type product {productId name image} unitAmount currency recurringIntervalCount recurringInterval recurringTrialPeriodDays";
    case "promotionCode":
      return "id active category couponCode createdAt currency country description modifiedAt percentage stripeCouponCodeId stripePromotionCodeId value";
    case "orders":
      return `id hasTrial hasSubscription firstName lastName companyName
        trialDays stripeInvoiceIds stripeSubscriptionIds amountDiscountRate amountTotalValue amountDiscount amountShipping amountSubtotal amountTax amountTotal amountTotalAfter
        billingAddressCity billingAddressCountry billingAddressLine1 billingAddressLine2 billingAddressPostalCode billingAddressState billingName braintreeCustomerId country couponCodes couponcode createdAt currency currencySymbol discounts email hasSubscription hasTrial language modifiedAt orderId orderType paymentType shippingAddressCity shippingAddressCountry shippingAddressLine1 shippingAddressLine2 shippingAddressPostalCode shippingAddressState shippingName shippingState shippingCourierService shippingItemsBrushCount shippingItemsBrushHeadCount shippingItemsGamesSubCount shippingItemsToothPasteCount stripeCustomerId stripeInvoiceId stripeSubscriptionId stripeTaxRateId tags taxRate trialDays userId phone invoicePaymentReferenceId braintreePaypalTransactionId braintreePaypalPayerEmail stripeSetupIntent stripePaymentIntent
        orderItems {  bundleId  bundleName  currencySymbol  image  infoDelivery  infoExplainer  infoPayment  isSubscription  priceAmount  priceAmountAfterTrial  priceDefaultAmount  priceId  priceOriginalAfterTrial  productId  productName  quantity  recurringInterval  recurringIntervalCount  recurringTrialPeriodDays  tags productBarcode productNameMarketing productNameOrder productSku } `;
    case "bundlepres":
    case "bundles":
      return `id bundleId  active name nameOrderEn nameMarketingEn nameOrderDe nameMarketingDe image items itemsGbp tags bundleTags isCrossedPriceVisible
        priceAmountOneTimeCrossedEur priceAmountOneTimeCrossedGbp  priceAmountOneTimeCrossedUsd priceAmountOneTimeCurrentEur  priceAmountOneTimeCurrentGbp priceAmountOneTimeCurrentUsd  priceAmountPayAfterTrialCrossedEur priceAmountPayAfterTrialCrossedGbp  priceAmountPayAfterTrialCrossedUsd priceAmountPayAfterTrialCurrentEur  priceAmountPayAfterTrialCurrentGbp priceAmountPayAfterTrialCurrentUsd  priceAmountPayNowCrossedEur  priceAmountPayNowCrossedGbp  priceAmountPayNowCrossedUsd  priceAmountPayNowCurrentEur  priceAmountPayNowCurrentGbp  priceAmountPayNowCurrentUsd  priceAmountRecurringCrossedEur priceAmountRecurringCrossedGbp  priceAmountRecurringCrossedUsd priceAmountRecurringCurrentEur  priceAmountRecurringCurrentGbp priceAmountRecurringCurrentUsd savingsEur savingsGbp savingsUsd
        images descriptionEn descriptionDe labels {text colour lang} index imageSubscription
        itemsExtended {active currency priceId product { description image name productId defaultPrice prodSku } recurringInterval recurringIntervalCount recurringTrialPeriodDays type unitAmount unitAmountDecimal }
        itemsExtendedGbp {active currency priceId product { description image name productId defaultPrice prodSku } recurringInterval recurringIntervalCount recurringTrialPeriodDays type unitAmount unitAmountDecimal }`;
    case "v2Bundlepres":
    case "v2Bundles":
      return `id bundleId name image locales products bundleTags index isCrossedPriceVisible note
        translations {description nameOrder nameMarketing images labels}
        prices { stripePriceIds stripePriceIdsExtended priceAmountPayNowCurrent priceAmountPayNowCrossed priceAmountPayAfterTrialCurrent }`;

    case "referralCodeIndividual":
      return `id userId email firstName lastName couponcode created couponValidTill vendorDbId couponText couponAmount modified couponRewardAmount bannerMessage sharedMessage `;
    case "referralCodeMessage":
      return `id createdAt updatedAt sharedMessage bannerMessage validTill amountPercents amountEur currency language`;
    case "users":
      return `id email oldEmail lastOpenedApp firstName lastName country  language dateRegistrationStarted phoneNumber
      subscriptionStart subscriptionEnd hasPremiumAccess lastLoginAt countryEmoji os
    subscriptions { createdAt updatedAt cancelledAt store subscriptionId status title endDate price latestPrice quantity }`;
    case "subscriptions":
      return `createdAt updatedAt cancelledAt store subscriptionId status title endDate price latestPrice quantity`;
    case "shopifyOrders":
      return `id  email status createdAt updatedAt shopifyId stripeId shopName
        customer { id stripeId email checkoutId acceptsMarketing braintreeId
          shippingAddress { firstName lastName country state city zipcode streetName houseNumber countryEmoji }
          billingAddress { firstName lastName country state city zipcode streetName houseNumber countryEmoji }
        } items { quantity id productId variantId productType title stripeProductId }`;
    case "locales":
      return `id name country countryFull countryEmoji defaultLanguage defaultCurrency defaultCurrencyFull defaultLanguageFull
      supportedLanguages supportedCurrencies createdAt updatedAt alUser alComment active`;
    case "allCountries":
      return `name id flag`;
    case "allLanguages":
    case "allCurrencies":
      return `name id`;
    case "internalUserGroups":
      return `id name userCount localeIds createdAt updatedAt`;
    case "internalUsers":
      return `name email groupId createdAt updatedAt`;
    case "emailTemplates":
      return `emailType description createdAt modifiedAt locales supportedLanguages updateMessage
      translations { mailchimpTemplateName subjectText previewText fromEmail fromName attachments }`;
    case "emailTypes":
      return `id name`;
    default:
      return "id";
  }
};

const skipCount = (resource) => {
  switch (resource) {
    case "allLanguages":
    case "allCountries":
    case "allCurrencies":
    case "emailTypes":
      return true;
    default:
      return false;
  }
};

const getListFields = (resource) => {
  switch (resource) {
    case "users":
      return `id email oldEmail lastOpenedApp firstName lastName country language dateRegistrationStarted phoneNumber
      subscriptionStart subscriptionEnd hasPremiumAccess lastLoginAt countryEmoji os`;
    case "v2Products":
    case "v2Productpres":
      return `id productId name image tags`;
    case "v2Bundles":
    case "v2Bundlepres":
      return `id bundleId name image locales products bundleTags index`;
    case "emailTemplates":
      return `emailType locales translationsCount languageLocales`;
    default:
      return fields(resource);
  }
};

const getUpdateFields = (resource) => {
  switch (resource) {
    case "locales":
      return `id name country defaultLanguage defaultCurrency supportedLanguages supportedCurrencies alComment`;
    case "internalUserGroups":
      return `id name localeIds`;
    case "internalUsers":
      return `id email name groupId`;
    case "emailTemplates":
      return `id emailType description locales updateMessage translationsCount languageLocales
      translations { mailchimpTemplateName subjectText previewText fromEmail fromName attachments }`;
    default:
      return fields(resource);
  }
};

const base64_ids = (resource) => {
  switch (resource) {
    case "emailProductDetailText":
      return true;
    case "promotionCode":
      return true;
    case "referralCodeMessage":
      return true;
    case "users":
      return true;
    case "shopifyOrders":
      return true;
    case "locales":
      return true;
    case "internalUserGroups":
      return true;
    default:
      return false;
  }
};
const field_ids = (resource) => {
  switch (resource) {
    case "products":
    case "productpres":
    case "v2Products":
    case "v2Productpres":
      return "productId";
    case "bundles":
    case "bundlepres":
    case "v2Bundles":
    case "v2Bundlepres":
      return "bundleId";
    case "productTags":
      return "tagId";
    case "emailProductDetailText":
      return "id";
    case "prices":
      return "priceId";
    case "orders":
      return "orderId";
    case "internalUsers":
      return "email";
    case "emailTemplates":
      return "emailType";
    default:
      return "id";
  }
};

const sentenceCaseResource = (resource) => {
  switch (resource) {
    case "users":
      return "User:";
    case "shopifyOrders":
      return "ShopifyOrderModel:";
    case "locales":
      return "LocaleModel:";
    case "internalUserGroups":
      return "InternalUserGroupModel:";

    default:
      return resource[0].toUpperCase() + resource.substring(1) + ":";
  }
};

const where_filter_custom_value = (filter, where_field) => {
  switch (where_field) {
    case "created_at__gte":
      return filter[where_field] + " 00:00:00";
    case "created_at__lte":
      return filter[where_field] + " 23:59:59";
    default:
      return filter[where_field];
  }
};
const custom_update_data = (resource, data) => {
  switch (resource) {
    case "bundlepres":
      data = omit(data, ["product", "unitAmount"]);
      ["itemsExtended", "itemsExtendedGbp"].forEach((key) => {
        if (key in data && data[key] !== undefined) {
          data[key].forEach((item) => {
            item.product = item.product.productId;
            delete item["product.productId"];
          });
        }
      });
      return data;
    case "locales":
    case "internalUserGroups":
    case "internalUsers":
      data = pick(data, getUpdateFields(resource).split(" "));
    default:
      return data;
  }
};
export const dataProvider = {
  getList: (resource, { sort, pagination, filter }) => {
    if (resource === "invoices-archive") {
      return dataProvider.getInvoices();
    }

    if (resource === "shipping") {
      resource = "orders";
    }

    const { field, order } = sort;
    const { page, perPage } = pagination;
    return axios_client
      .post("", {
        // query: `
        //     query ($limit: Int, $offset: Int, $order_by: [${resource}_order_by!], $where: ${resource}BoolExp) {
        //         ${resource}(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
        //             ${fields(resource)}
        //         }
        //         ${resource}_aggregate(where: $where) {
        //             aggregate {
        //                 count
        //             }
        //         }
        //     }`,
        // $order_by: ${resource[0].toUpperCase() + resource.substring(1)}SortExp,
        query: `
            query (
              $limit: Int,
              $offset: Int,
              $order_by: [SortField],
              $where: [WhereField],
            ) {
                ${resource} (limit: $limit, offset: $offset, orderBy: $order_by, where: $where)  {
                    ${getListFields(resource)}
                }

                ${skipCount(resource) ? "" : resource + "Count(where: $where)"}
            }`,
        variables: {
          limit: perPage,
          offset: (page - 1) * perPage,
          order_by: [{ FieldName: field, SortOrder: order.toLowerCase() }],
          where: Object.keys(filter).map((key) => ({
            field: key,
            value: JSON.stringify(where_filter_custom_value(filter, key)),
          })),
          // where: Object.keys(filter).reduce(
          //   (prev, key) => ({
          //     ...prev,
          //     [key]: { Eq: filter[key] },
          //   }),
          //   {}
          // ),
        },
      })
      .then((result) => ({
        data: result.data.data[resource].map((res) => {
          res.id = base64_ids(resource)
            ? atob(res[field_ids(resource)]).replace(sentenceCaseResource(resource), "")
            : res[field_ids(resource)];
          return res;
        }),
        // total: result.data[`${resource}_aggregate`].aggregate.count,
        total: result.data.data[`${resource}Count`],
      }));
  },
  getOne: (resource, params) => {
    return axios_client
      .post("", {
        query: `
            query ($id: String!) {
                ${resource}ByPk(id: $id) {
                    ${params.fields || fields(resource)}
                }
            }`,
        variables: {
          id: params.id,
        },
      })
      .then((result) => {
        if (result.data.data[`${resource}ByPk`] === null) {
          return { data: {} };
        }
        result.data.data[`${resource}ByPk`].id = base64_ids(resource)
          ? atob(result.data.data[`${resource}ByPk`][field_ids(resource)]).replace(sentenceCaseResource(resource), "")
          : result.data.data[`${resource}ByPk`][field_ids(resource)];
        return {
          data: result.data.data[`${resource}ByPk`],
        };
      });
  },
  getMany: (resource, params) => {
    return axios_client
      .post("", {
        query: `
        query ($where: [WhereField]) {
                ${resource}(where: $where) {
                    ${fields(resource)}
                }
            }`,
        variables: {
          // where: params.ids.reduce(
          //   (prev, item, idx, arr) => ({
          //     // ...prev,
          //     [Object.keys(item)[0]]: { In: Object.values(item)[0] },
          //   }),
          //   {}
          // ),
          where: Object.keys(params)
            .filter((key) => key !== "meta")
            .map((key) => ({
              field: key === "ids" ? "pk__in" : key,
              // operator: "in",
              value: JSON.stringify(params[key]),
            })),
        },
      })
      .then((result) => ({
        data: result.data.data[resource].map((res) => {
          res.id = base64_ids(resource)
            ? atob(res[field_ids(resource)]).replace(sentenceCaseResource(resource), "")
            : res[field_ids(resource)];
          return res;
        }),
      }));
  },
  getManyReference: (resource, { target, id, sort, pagination, filter }) => {
    const { field, order } = sort;
    const { page, perPage } = pagination;
    return axios_client
      .post("", {
        query:
          // `
          //     query ($limit: Int, $offset: Int, $order_by: [${resource}_order_by!], $where: ${
          //   resource[0].toUpperCase() + resource.substring(1)
          // }BoolExp) {
          //         ${resource}(limit: $limit, offset: $offset, order_by: $order_by, where: $where) {
          //             ${fields(resource)}
          //         }
          //         ${resource}_aggregate(where: $where) {
          //             aggregate {
          //                 count
          //             }
          //         }
          //     }
          // `
          `
            query (
              $limit: Int,
              $offset: Int,
              $order_by:${resource[0].toUpperCase() + resource.substring(1)}SortExp,
              $where: ${resource[0].toUpperCase() + resource.substring(1)}BoolExp
            ) {
                ${resource}(where: $where) {${fields(resource)}}
                ${resource}Count(where: $where)
            }
        `,
        variables: {
          limit: perPage,
          offset: (page - 1) * perPage,
          order_by: {
            sort: [{ fieldName: field, sortOrder: order.toLowerCase() }],
          },
          where: Object.keys(filter).reduce(
            (prev, key) => ({
              ...prev,
              [key]: { Eq: filter[key] },
            }),
            { [target]: { Eq: id } }
          ),
        },
      })
      .then((result) => ({
        data: result.data.data[resource],
        // total: result.data[`${resource}_aggregate`].aggregate.count,
        total: result.data.data[`${resource}_count`],
      }));
  },
  create: (resource, params) => {
    return axios_client
      .post("", {
        query: `
            mutation ($data: ${resource[0].toUpperCase() + resource.substring(1)}SetInput!) {
              ${resource}Create(set: $data) {
                ${resource}{
                  ${fields(resource)}
                }
              }
            }`,
        variables: {
          data: custom_update_data(resource, omit(params.data, ["__typename"])),
        },
      })
      .then((result) => {
        if (result.data.errors && result.data.errors.length > 0) {
          throw result.data.errors[0].message;
        }
        result.data.data[`${resource}Create`][`${resource}`].id = base64_ids(resource)
          ? atob(result.data.data[`${resource}Create`][`${resource}`][field_ids(resource)]).replace(
              sentenceCaseResource(resource),
              ""
            )
          : result.data.data[`${resource}Create`][`${resource}`][field_ids(resource)];
        return {
          data: result.data.data[`${resource}Create`][`${resource}`],
        };
      });
  },
  update: (resource, params) => {
    return axios_client
      .post("", {
        query: `
            mutation ($id: String!, $data: ${resource[0].toUpperCase() + resource.substring(1)}SetInput!) {
                ${resource}UpdateByPk(pkColumns: { id: $id }, set: $data) {
                  ${resource}{
                    ${getUpdateFields(resource)}
                  }
                }
            }`,
        variables: {
          id: params.id,
          data: custom_update_data(resource, omit(params.data, ["__typename"])),
        },
      })
      .then((result) => {
        if (result.data.errors && result.data.errors.length > 0) {
          throw result.data.errors[0].message;
        }
        return {
          data: result.data.data[`${resource}UpdateByPk`][`${resource}`],
        };
      });
  },
  updateMany: (resource, params) => {
    if (resource === "shipping") {
      resource = "orders";
    }
    return axios_client
      .post("", {
        query: `
            mutation ($where: [WhereField], $data: ${resource[0].toUpperCase() + resource.substring(1)}SetInput!) {
                ${resource}UpdateMany(where: $where, set: $data) {
                    affectedIds
                }
            }`,
        variables: {
          where: Object.keys(params)
            .filter((key) => key !== "meta" && key !== "data")
            .map((key) => ({
              field: key === "ids" ? "pk__in" : key,
              value: JSON.stringify(params[key]),
            })),
          data: omit(params.data, ["__typename"]),
        },
      })
      .then((result) => ({
        data: result.data.data[`${resource}UpdateMany`]["affectedIds"],
      }));
  },
  delete: (resource, params) => {
    return axios_client
      .post("", {
        query: `
            mutation ($id: String!) {
              ${resource}DeleteByPk(pkColumns: { id: $id }) {
                ${resource}{
                  ${fields(resource)}
                }
              }
            }`,
        variables: {
          id: params.id,
        },
      })
      .then((result) => ({
        data: result.data.data[`${resource}DeleteByPk`][`${resource}`],
      }));
  },
  deleteMany: (resource, { ids }) => {
    return axios_client
      .post("", {
        query: `
            mutation ($where: [WhereField]!) {
              ${resource}Delete(where: $where) {
                ${resource}{
                  ${fields(resource)}
                }
              }
            }`,
        variables: {
          where: [
            {
              field: "pk__in",
              value: JSON.stringify(ids),
            },
          ],
        },
      })
      .then((result) => ({
        data: result.data.data[`${resource}Delete`][`${resource}`],
      }));
  },
  getInvoices: (resource, params) => {
    return axios_client
      .post(apiBaseUrl, {
        query: `query InvoicesArchive {
            data {
                invoicesArchive {
                    id
                    year
                    downloadLink
                }
                total
            }
        }`,
      })
      .then((result) => ({
        data: result.data.data.data.invoicesArchive,
        total: result.data.data.data.total,
      }));
  },
};

export default dataProvider;
