import { downloadCSV } from "react-admin";
import jsonExport from "jsonexport/dist";

const exporter = (orders: [GQL.Orders]) => {
  const ordersForExport = orders.map((order: GQL.Orders) => {
    const {
      currencySymbol,
      shippingName,
      // amountTax,
      // amountDiscount,
      amountShipping,
      amountSubtotal,
      amountTotalAfter,
      billingName,
      billingAddressCity,
      billingAddressLine1,
      billingAddressLine2,
      billingAddressPostalCode,
      billingAddressCountry,
      billingAddressState,
      country,
      couponCodes,
      discounts,
      hasSubscription,
      hasTrial,
      language,
      modifiedAt,
      orderType,
      paymentType,
      stripeSubscriptionId,
      stripeTaxRateId,
      tags,
      taxRate,
      trialDays,
      userId,
      // orderItems,
      orderId,
      invoicePaymentReferenceId,
      braintreePaypalTransactionId,
      stripeSetupIntent,
      stripePaymentIntent,
      // firstName lastName companyName stripeInvoiceIds stripeSubscriptionIds amountDiscountRate amountTotalValue

      ...orderForExport
    } = order; // omit backlinks and author

    if (orderForExport !== null && orderForExport.orderItems !== null && orderForExport.orderItems !== undefined) {
      const orderItemsForExport = orderForExport.orderItems.map((orderItem: any) => {
        const {
          bundleId,
          bundleName,
          currencySymbol,
          image,
          infoDelivery,
          infoExplainer,
          infoPayment,
          isSubscription,
          priceAmount,
          priceAmountAfterTrial,
          priceDefaultAmount,
          priceId,
          priceOriginalAfterTrial,
          recurringInterval,
          recurringIntervalCount,
          recurringTrialPeriodDays,
          ...orderItemForExport
        } = orderItem; // omit backlinks and author

        orderItemForExport.interval = orderItem.recurringIntervalCount + " " + orderItem.recurringInterval;
        if (orderItemForExport.interval === "12 month") {
          orderItemForExport.interval = "1 year";
        }
        return orderItemForExport;
      });

      orderForExport.orderItems = orderItemsForExport;
    }

    // // orderForExport.tags = orderItems.tags;
    // // orderForExport.productName = orderItems.productName;
    // // orderForExport.productId = orderItems.productId;
    // }
    //   return orderItem;
    // })
    // orderForExport.orderItems = orderItemsForExport;
    // orderForExport.author_name = post.author.name; // add a field
    return orderForExport;
  });

  jsonExport(
    ordersForExport,
    {
      headers: [
        "id",
        "firstName",
        "lastName",
        "companyName",
        // "shippingName",
        "shippingAddressLine1",
        "shippingAddressLine2",
        "shippingAddressPostalCode",
        "shippingAddressCity",
        "shippingAddressState",
        "shippingAddressCountry",
        "phone",
        "amountTotal",
        "email",
        "orderItems.quantity", //
        "orderItems.productSku",
        "orderItems.tags", //
        "orderItems.productName", //
        "orderItems.productNameMarketing", //
        "orderItems.productNameOrder", //
        "orderItems.productBarcode",
        "orderItems.productId", //
        "couponcode",
        "createdAt",
        "stripeCustomerId",
        "stripeInvoiceId",
        "braintreeCustomerId",
        "amountTax",
        "amountDiscount",
        "amountDiscountRate",
        "amountTotalValue",
        // "amountTotal",
        "currency",
        "orderItems.interval",
      ], // order fields in the export
      rename: [
        "Order Id",
        "First Name",
        "Last Name",
        "Company Name",
        // "Shipping Name",
        "Address Line1",
        "Address Line2",
        "PostalCode",
        "City",
        "State",
        "Country",
        "Phone",
        "Total",
        // "Currency",
        "Email",
        "Quantity", //
        "Sku",
        "Tags", //
        "Product Name", //
        "Marketing Name", //
        "Order Name", //
        "Barcode",
        "Product stripe Id", //
        "Couponcode",
        "Created at",
        "Stripe Customer Id",
        "Stripe Invoice Id",
        "Braintree Customer Id",
        "Tax",
        "Discount",
        "Discount Rate",
        "Total Value",
        // "Total",
        "Currency",
        "Recurring Interval",
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Orders"); // download as 'posts.csv` file
    }
  );
};

const exporterShipping = (orders: [GQL.Orders]) => {
  const ordersForExport = orders.map((order: GQL.Orders) => {
    if (order.country !== "DE" && order.country !== "AT") {
      return null;
    }

    const {
      currencySymbol,
      shippingName,
      // amountTax,
      // amountDiscount,
      amountShipping,
      amountSubtotal,
      amountTotalAfter,
      billingName,
      billingAddressCity,
      billingAddressLine1,
      billingAddressLine2,
      billingAddressPostalCode,
      billingAddressCountry,
      billingAddressState,
      country,
      couponCodes,
      discounts,
      hasSubscription,
      hasTrial,
      language,
      modifiedAt,
      orderType,
      paymentType,
      stripeSubscriptionId,
      stripeTaxRateId,
      tags,
      taxRate,
      trialDays,
      userId,
      // orderItems,
      orderId,
      invoicePaymentReferenceId,
      braintreePaypalTransactionId,
      stripeSetupIntent,
      stripePaymentIntent,
      // firstName lastName companyName stripeInvoiceIds stripeSubscriptionIds amountDiscountRate amountTotalValue

      ...orderForExport
    } = order; // omit backlinks and author

    if (orderForExport !== null && orderForExport.orderItems !== null && orderForExport.orderItems !== undefined) {
      const orderItemsForExport = orderForExport.orderItems.map((orderItem: any) => {
        const {
          bundleId,
          bundleName,
          currencySymbol,
          image,
          infoDelivery,
          infoExplainer,
          infoPayment,
          isSubscription,
          priceAmount,
          priceAmountAfterTrial,
          priceDefaultAmount,
          priceId,
          priceOriginalAfterTrial,
          recurringInterval,
          recurringIntervalCount,
          recurringTrialPeriodDays,
          ...orderItemForExport
        } = orderItem; // omit backlinks and author

        if (orderItem.recurringTrialPeriodDays !== 0 || orderItem.productSku.toUpperCase().indexOf("GAMES") !== -1) {
          return null;
        }

        orderItemForExport.interval = orderItem.recurringIntervalCount + " " + orderItem.recurringInterval;
        if (orderItemForExport.interval === "12 month") {
          orderItemForExport.interval = "1 year";
        }
        return orderItemForExport;
      });

      orderForExport.orderItems = orderItemsForExport;
    }

    // // orderForExport.tags = orderItems.tags;
    // // orderForExport.productName = orderItems.productName;
    // // orderForExport.productId = orderItems.productId;
    // }
    //   return orderItem;
    // })
    // orderForExport.orderItems = orderItemsForExport;
    // orderForExport.author_name = post.author.name; // add a field
    return orderForExport;
  });

  jsonExport(
    ordersForExport,
    {
      headers: [
        "id",
        "firstName",
        "lastName",
        "companyName",
        // "shippingName",
        "shippingAddressLine1",
        "shippingAddressLine2",
        "shippingAddressPostalCode",
        "shippingAddressCity",
        "shippingAddressState",
        "shippingAddressCountry",
        "phone",
        "amountTotal",
        "email",
        "orderItems.quantity", //
        "orderItems.productSku",
        "orderItems.tags", //
        "orderItems.productName", //
        "orderItems.productNameMarketing", //
        "orderItems.productNameOrder", //
        "orderItems.productBarcode",
        "orderItems.productId", //
        "couponcode",
        "createdAt",
        "stripeCustomerId",
        "stripeInvoiceId",
        "braintreeCustomerId",
        "amountTax",
        "amountDiscount",
        "amountDiscountRate",
        "amountTotalValue",
        // "amountTotal",
        "currency",
        "orderItems.interval",
      ], // order fields in the export
      rename: [
        "Order Id",
        "First Name",
        "Last Name",
        "Company Name",
        // "Shipping Name",
        "Address Line1",
        "Address Line2",
        "PostalCode",
        "City",
        "State",
        "Country",
        "Phone",
        "Total",
        // "Currency",
        "Email",
        "Quantity", //
        "Sku",
        "Tags", //
        "Product Name", //
        "Marketing Name", //
        "Order Name", //
        "Barcode",
        "Product stripe Id", //
        "Couponcode",
        "Created at",
        "Stripe Customer Id",
        "Stripe Invoice Id",
        "Braintree Customer Id",
        "Tax",
        "Discount",
        "Discount Rate",
        "Total Value",
        // "Total",
        "Currency",
        "Recurring Interval",
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Orders"); // download as 'posts.csv` file
    }
  );
};

const exporterDiscount = (orders: [GQL.Orders]) => {
  const ordersForExport = orders.map((order: GQL.Orders) => {
    const {
      currencySymbol,
      shippingName,
      // amountTax,
      // amountDiscount,
      // amountShipping,
      // amountSubtotal,
      amountTotalAfter,
      billingName,
      billingAddressCity,
      billingAddressLine1,
      billingAddressLine2,
      billingAddressPostalCode,
      billingAddressCountry,
      billingAddressState,
      country,
      couponCodes,
      discounts,
      hasSubscription,
      hasTrial,
      language,
      modifiedAt,
      orderType,
      paymentType,
      stripeSubscriptionId,
      stripeTaxRateId,
      tags,
      taxRate,
      trialDays,
      userId,
      orderItems,
      orderId,
      invoicePaymentReferenceId,
      braintreePaypalTransactionId,
      stripeSetupIntent,
      stripePaymentIntent,
      // firstName lastName companyName stripeInvoiceIds stripeSubscriptionIds amountDiscountRate
      amountTotalValue,
      braintreeCustomerId,
      shippingAddressLine1,
      shippingAddressLine2,
      shippingAddressPostalCode,
      shippingAddressCity,
      shippingAddressState,
      shippingAddressCountry,
      stripeCustomerId,
      stripeInvoiceId,
      phone,
      stripeInvoiceIds,
      stripeSubscriptionIds,

      ...orderForExport
    } = order; // omit backlinks and author

    // if (orderForExport !== null && orderForExport.orderItems !== null && orderForExport.orderItems !== undefined) {
    //   const orderItemsForExport = orderForExport.orderItems.map((orderItem: any) => {
    //     const {
    //       bundleId,
    //       bundleName,
    //       currencySymbol,
    //       image,
    //       infoDelivery,
    //       infoExplainer,
    //       infoPayment,
    //       isSubscription,
    //       priceAmount,
    //       priceAmountAfterTrial,
    //       priceDefaultAmount,
    //       priceId,
    //       priceOriginalAfterTrial,
    //       recurringInterval,
    //       recurringIntervalCount,
    //       recurringTrialPeriodDays,
    //       productId,
    //       tags,
    //       productBarcode,
    //       productNameMarketing,
    //       productNameOrder,
    //       ...orderItemForExport
    //     } = orderItem; // omit backlinks and author
    //     orderItemForExport.priceAmount = orderItemForExport.priceAmount ? orderItemForExport.priceAmount / 100 : 0;
    //     return orderItemForExport;
    //   });

    //   orderForExport.orderItems = orderItemsForExport;
    // }

    orderForExport.amountDiscount = orderForExport.amountDiscount ? orderForExport.amountDiscount / 100 : 0;
    orderForExport.amountSubtotal = orderForExport.amountSubtotal ? orderForExport.amountSubtotal / 100 : 0;
    orderForExport.amountShipping = orderForExport.amountShipping ? orderForExport.amountShipping / 100 : 0;
    orderForExport.amountTax = orderForExport.amountTax ? orderForExport.amountTax / 100 : 0;
    orderForExport.amountTotal = orderForExport.amountTotal ? orderForExport.amountTotal / 100 : 0;
    // orderForExport.amountTotalValue = orderForExport.amountTotalValue ? orderForExport.amountTotalValue / 100 : 0;
    // // orderForExport.quantity = orderItems.quantity;
    // // orderForExport.tags = orderItems.tags;
    // // orderForExport.productName = orderItems.productName;
    // // orderForExport.productId = orderItems.productId;
    // }
    //   return orderItem;
    // })
    // orderForExport.orderItems = orderItemsForExport;
    // orderForExport.author_name = post.author.name; // add a field
    return orderForExport;
  });

  jsonExport(
    ordersForExport,
    {
      headers: [
        "id",
        "createdAt",
        "firstName",
        "lastName",
        "companyName",
        "email",
        "couponcode",
        "amountDiscount",
        // "amountTotalValue",
        "amountDiscountRate",
        "amountSubtotal",
        "amountShipping",
        "amountTax",
        "amountTotal",
        "currency",
        // "shippingName",
        // "shippingAddressLine1",
        // "shippingAddressLine2",
        // "shippingAddressPostalCode",
        // "shippingAddressCity",
        // "shippingAddressState",
        // "shippingAddressCountry",
        // "phone",
        // "orderItems.productSku",
        // "orderItems.productName", //
        // // "amountTotal",
        // "orderItems.priceAmount",
        // "orderItems.quantity", //
        // "orderItems.tags", //
        // "orderItems.productNameMarketing", //
        // "orderItems.productNameOrder", //
        // "orderItems.productBarcode",
        // "orderItems.productId", //
        // "stripeCustomerId",
        // "stripeInvoiceId",
        // "braintreeCustomerId",
      ], // order fields in the export
      rename: [
        "Order Id",
        "Created at",
        "First Name",
        "Last Name",
        "Company Name",
        "Email",
        "Couponcode",
        "Discount Amount",
        // "Total Value",
        "Discount Rate",
        "Original Value",
        "Shipping cost",
        "Tax",
        "Invoiced Total",
        "Currency",
        // "Shipping Name",
        // "Address Line1",
        // "Address Line2",
        // "PostalCode",
        // "Shipping City",
        // "State",
        // "Country",
        // "Phone",
        // "Sku",
        // "Product Name", //
        // "Line Item Price",
        // // "Currency",
        // "Line Item Quantity", //
        // "Tags", //
        // "Marketing Name", //
        // "Order Name", //
        // "Barcode",
        // "Product stripe Id", //
        // "Stripe Customer Id",
        // "Stripe Invoice Id",
        // "Braintree Customer Id",
      ], // order fields in the export
    },
    (err: any, csv: any) => {
      downloadCSV(csv, "Orders"); // download as 'posts.csv` file
    }
  );
};

export default exporterShipping;
