import { Grid } from "@mui/material";
import { useCallback } from "react";
import { NumericFormat } from "react-number-format";

import {
  ArrayField,
  BulkDeleteButton,
  ChipField,
  Create,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  FunctionField,
  Labeled,
  List,
  NumberInput,
  Pagination,
  required,
  SaveButton,
  SelectArrayInput,
  SelectInput,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  useCreate,
  useNotify,
  useRedirect,
  useUpdate,
} from "react-admin";
import React from "react";
import Currency from "../components/currency";

function sort_by_id() {
  return function (elem1: any, elem2: any) {
    if (elem1.id < elem2.id) {
      return -1;
    } else if (elem1.id > elem2.id) {
      return 1;
    } else {
      return 0;
    }
  };
}

const CouponCodeCategrories = [
  { id: "COUPON", name: "COUPON" },
  { id: "PRAXISDIENSTE", name: "PRAXISDIENSTE" },
  { id: "DENTIST", name: "DENTIST" },
  { id: "NEWSLETTER", name: "NEWSLETTER" },
  { id: "INFLUENCER", name: "INFLUENCER" },
  { id: "ORGANIC", name: "ORGANIC" },
  { id: "META", name: "META" },
  { id: "SEARCH", name: "SEARCH" },
  { id: "DISPLAY", name: "DISPLAY" },
  { id: "REFERRAL", name: "REFERRAL" },
  { id: "PROFESSIONAL", name: "PROFESSIONAL" },
  { id: "GOOGLE", name: "GOOGLE" },
  { id: "SOCIAL_MEDIA", name: "SOCIAL_MEDIA" },
  { id: "AFFILIATE", name: "AFFILIATE" },
  { id: "TEST", name: "TEST" },
  { id: "SUPPORT", name: "SUPPORT" },
  { id: "EVENTS", name: "EVENTS" },
  { id: "PR", name: "PR" },
  { id: "COOPERATIONS", name: "COOPERATIONS" },
].sort(sort_by_id());

const CountriesSupported = [
  { id: "DE", name: "DE" },
  { id: "AT", name: "AT" },
  { id: "GB", name: "GB" },
  { id: "FR", name: "FR" },
  { id: "IT", name: "IT" },
].sort(sort_by_id());

const PromotionCodeFilters = [
  //   <TextInput label="ID" source="pk" alwaysOn />,
  <TextInput label="Coupon code" source="couponCode__icontains" alwaysOn />,
  <TextInput label="Description" source="description__icontains" alwaysOn />,
  <SelectInput source="category" choices={CouponCodeCategrories} alwaysOn />,
  <NumberInput label="Precent" source="percentage" alwaysOn />,
  <NumberInput label="Value" source="value" alwaysOn />,
  <SelectInput
    label="Currency"
    source="currency__icontains"
    choices={[
      { id: "eur", name: "EUR" },
      { id: "gbp", name: "GBP" },
      { id: "usd", name: "USD" },
    ]}
    alwaysOn
  />,
  // <TextInput label="Email" source="email__icontains" defaultValue="" />,
  // <DateInput label="Date from" source="created_at__gte" alwaysOn defaultValue="" />,
  // <DateInput label="Date to" source="created_at__lte" alwaysOn defaultValue="" />,
];

const PromotionCodeBulkActionButtons = () => (
  <>
    <BulkDeleteButton label="Archive & Deactivate" />
  </>
);

const MyPagination = () => <Pagination rowsPerPageOptions={[10, 25, 50, 100, 500, 1000]} />;

const MyToolbar = (props: any) => {
  const redirect = useRedirect();
  const notify = useNotify();
  return (
    <Toolbar {...props} sx={{ justifyContent: "space-between" }}>
      <SaveButton label="Update" />
      <DeleteButton label="Archive & Deactivate" sx={{ textAlign: "right" }} />
    </Toolbar>
  );
};

// active?: Maybe<Scalars["Boolean"]>;
// category?: Maybe<Scalars["String"]>;
// couponCode?: Maybe<Scalars["String"]>;
// createdAt?: Maybe<Scalars["DateTime"]>;
// currency?: Maybe<Scalars["String"]>;
// description?: Maybe<Scalars["String"]>;
// id: Scalars["ID"];
// modifiedAt?: Maybe<Scalars["DateTime"]>;
// percentage?: Maybe<Scalars["Float"]>;
// stripeCouponCodeId?: Maybe<Scalars["String"]>;
// stripePromotionCodeId?: Maybe<Scalars["String"]>;
// value?: Maybe<Scalars["Int"]>;

export const PromotionCodeList = () => (
  <List filters={PromotionCodeFilters} pagination={<MyPagination />}>
    <Datagrid bulkActionButtons={<PromotionCodeBulkActionButtons />}>
      {/* rowClick="edit" */}
      <TextField source="category" />
      <TextField source="couponCode" />
      <TextField source="description" />
      <TextField source="percentage" />
      <TextField source="country" />
      <FunctionField
        textAlign="right"
        label="Value"
        render={(record: { value: number; currency: string }) => {
          return <Currency price={record.value} currency={record.currency} />;
        }}
      />
      <TextField source="currency" />
      <EditButton />
    </Datagrid>
  </List>
);

const PromotionCodeCreateEditForm = () => {
  return (
    <Grid container columnSpacing={2}>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={12} sm={8}>
        <SelectInput source="category" choices={CouponCodeCategrories} fullWidth={true} defaultValue={"COUPON"} />
      </Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={12} sm={8}>
        <Labeled label="Code" fullWidth={true}>
          <TextInput
            fullWidth={true}
            label="Coupon Code"
            source="couponCode"
            helperText="Coupon only can contains letters A-Z no spaces or special characters."
          />
        </Labeled>
      </Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={12} sm={8}>
        <Labeled label="Description" fullWidth={true}>
          <TextInput source="description" fullWidth={true} />
        </Labeled>
      </Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={12} sm={8}>
        <Labeled label="Country" fullWidth={true}>
          <SelectArrayInput
            source="country"
            choices={CountriesSupported}
            fullWidth={true}
            format={(v) => (typeof v === "string" ? v.split(",") : [])}
            parse={(v) => (typeof v === "object" ? v.toString() : "")}
            helperText="Select in which countries the coupon code will be active."
          />
        </Labeled>
      </Grid>
      <Grid item xs={0} sm={2}></Grid>

      <Grid item xs={12} sm={12} sx={{ textAlign: "center", fontSize: "14px", color: "#666666", padding: "10px" }}>
        Choose a discount type <br />
        Precentage or Value <br />
        and fill in the details
      </Grid>

      <Grid item xs={6} sm={5} sx={{ textAlign: "right" }}>
        <Labeled label="Percentage Discount">
          <NumberInput min={0} max={100} fullWidth label="Percent Off" source="percentage" />
        </Labeled>
      </Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={6} sm={5}>
        <Labeled label="Value Discount">
          <TextInput
            label="Value Off"
            type="number"
            // value={values.toFixed(2)}
            // onChange={handleChange}
            // inputProps={{
            //   inputComponent: NumberFormatCustom,
            // }}
            format={(v) => (typeof v === "number" ? v / 100 : 0)}
            // format={(v) => (v / 100).toFixed(2)}
            parse={(v) => parseInt((v * 100).toFixed(0))}
            source="value"
          />
        </Labeled>
        {/* <Labeled label="Value Discount">
          <NumberInput min={0} label="Value Off" source="value" />
        </Labeled> */}
      </Grid>

      <Grid item xs={6} sm={5}></Grid>
      <Grid item xs={0} sm={2}></Grid>
      <Grid item xs={6} sm={5}>
        {" "}
        <SelectInput
          source="currency"
          choices={[
            { id: "all", name: "Select a currency" },
            { id: "eur", name: "EUR" },
            { id: "gbp", name: "GBP" },
          ]}
          validate={required()}
          defaultValue={"eur"}
        />
      </Grid>
    </Grid>
  );
};

export const PromotionCodeEdit = () => {
  const [update, { isLoading, error }] = useUpdate();
  const save = useCallback(
    async (values: any) => {
      try {
        await update("promotionCode", { id: values.id, data: values, previousData: values }, { returnPromise: true });
      } catch (error: any) {
        if (error!.body!.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [update]
  );
  return (
    <Edit>
      <SimpleForm validate={validatePromotionCodeCreation} toolbar={<MyToolbar />}>
        <PromotionCodeCreateEditForm></PromotionCodeCreateEditForm>
      </SimpleForm>
      {/* <SimpleForm toolbar={<MyToolbar />}>
        <SimpleShowLayout>
          <TextField source="category" />
          <TextField source="couponCode" />
          <TextField source="description" />
          <TextField source="country" />
          <TextField source="percentage" />
          <TextField source="value" />
          <TextField source="currency" />
        </SimpleShowLayout>
      </SimpleForm> */}
    </Edit>
  );
};

const validatePromotionCodeCreation = (values: any) => {
  let errors: any = {};
  const couponRegex = /^[A-Za-z0-9]+$/;
  if (!couponRegex.test(values.couponCode)) {
    errors.couponCode = "Coupon only can contains letters A-Z a-z no spaces or special characters.";
  }
  if (!values.percentage && values.value && values.currency == "all") {
    errors.currency = "Currency must be specified for value-based Promotion Codes";
  }
  if (!values.percentage && !values.value) {
    errors.value = "Precentage or Value must be specified";
    errors.percentage = "Precentage or Value must be specified";
  }
  if (!values.country && values.country !== "") {
    errors.country = "Please select atleast one country";
  }
  if (values.value) {
    console.log(values.value);
    if (parseInt(values.value.toFixed(0)) !== parseFloat(values.value.toFixed(2))) {
      errors.value = "Value can only contains 2 digits. Like 10.55 not 10.5567";
    }
  }
  return errors;
};

export const PromotionCodeCreate = () => {
  const [create] = useCreate();
  const save = useCallback(
    async (values: any) => {
      try {
        await create("promotionCode", { data: values }, { returnPromise: true });
      } catch (error: any) {
        if (error!.body!.errors) {
          // The shape of the returned validation errors must match the shape of the form
          return error.body.errors;
        }
      }
    },
    [create]
  );

  return (
    <Create>
      <SimpleForm validate={validatePromotionCodeCreation}>
        <PromotionCodeCreateEditForm></PromotionCodeCreateEditForm>
      </SimpleForm>
    </Create>
  );
};
