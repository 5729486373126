import {
  DatagridConfigurable,
  DateField,
  FilterButton,
  List,
  SearchInput,
  SelectColumnsButton,
  ShowButton,
  TextField,
  TopToolbar,
  WrapperField,
} from "react-admin";

import StartDateEndDateInput from "../components/startDateEndDateInput";
import grapheneDataProvider from "../providers/grapheneDataProvider2";

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
  </TopToolbar>
);

const userFilters = [
  <SearchInput source="email__icontains" alwaysOn={true} placeholder="Email" />,
  <SearchInput
    source="firstName__icontains"
    placeholder="First Name"
    alwaysOn={true}
  />,
  <SearchInput
    source="lastName__icontains"
    placeholder="Last Name"
    alwaysOn={true}
  />,
  <SearchInput source="oldEmail" placeholder="Old Email" />,
  <StartDateEndDateInput source="dateRegistrationStarted" />,
  <StartDateEndDateInput source="subscriptionStart" />,
  <StartDateEndDateInput source="subscriptionEnd" />,
];

const UsersList = (props: any) => {
  return (
    <List
      {...props}
      actions={<ListActions />}
      dataProvider={grapheneDataProvider}
      filters={userFilters}
      title={"Customers"}
      sort={{ field: "dateRegistrationStarted", order: "DESC" }}
    >
      <DatagridConfigurable bulkActionButtons={false}>
        <TextField source="email" />
        <TextField source="firstName" />
        <TextField source="lastName" />
        <TextField
          source="countryEmoji"
          sx={{ fontSize: "24px" }}
          label="Country"
        />
        <TextField source="language" />
        <DateField source="subscriptionStart" />
        <DateField source="subscriptionEnd" />
        <DateField source="dateRegistrationStarted" label="Created" />
        <DateField source="lastLoginAt" label="Last Login" />
        <WrapperField label="Actions">
          <ShowButton />
        </WrapperField>
      </DatagridConfigurable>
    </List>
  );
};

export default UsersList;
